import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { FaEnvelope } from 'react-icons/fa';
import { toast, ToastContainer } from "react-toastify";


const Forget = () => {
  const [email, setEmail] = useState('');

 

  const handleResetPassword = async () => {
   
      console.log("reset email sent to " + email);
      const emaildata = JSON.stringify({
        email: email,
      
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://oizvehadar.com/mailjs/generate-reset-link',
        headers: {
          'Content-Type': 'application/json'
        },
        data: emaildata
      };
  
      try {
        const response = await axios.request(config);
        console.log(response.data);
        toast.success('reset email sent to:',  email);
      } catch (error) {
        console.error(error.message);
        toast.error('Error sending password reset email:', error.message);
      } 
      
    
  };
 

  return (
    <>
      <div className="flex justify-center p-10 m-10 items-center">
      <ToastContainer />
        <div className="w-1/2">
          {/* Image */}
          <img
            src="../images/forget.svg"
            alt="login"
            className="w-250 h-250 rounded-full"
          />
        </div>
        <div className="w-1/2">
          <h1 className="text-center text-[40px]">{t("forget_pass")}</h1>
          <div className="mt-4">
            {/* Email input with icon */}
            <div className="relative">
              <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
              <input
                type="email"
                placeholder={t("email")}
                name="email_One"
                    
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
              />
            </div>
           
            {/* Submit button */}
            <button className="w-full h-10 bg-red-800 text-white rounded-md hover:bg-red-600" onClick={ handleResetPassword}>
            {t("submit")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forget;
