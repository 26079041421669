import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import BASE_URL from "../constant";
import { Interweave, Markup } from "interweave";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

const Donate = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState("");
  const [content, setContent] = useState("");
  useEffect(() => {
    fetchDonationstext();
    // fetchDonations();
  }, []);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "en"
  );

  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
  });
  const [isVolunteer, setIsVolunteer] = useState(false); // State to track if "נתנדב עי" checkbox is checked
  const fetchDonationstext = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api_donations.php/donation_text`
      );
      console.log(response.data);
      setContent(response.data.donation_text);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleVolunteerCheck = (e) => {
    setIsVolunteer(e.target.checked);
    // Clear name field when volunteer checkbox is unchecked
    if (!e.target.checked) {
      setName("");
    }
  };
  const [formData, setFormData] = useState({
    date: new Date(), // Default to today's date
    forSoulElevation: "",
    forHealing: "",
    forSalvation: "",
    forSuccess: "",
    donorName: "",
    anonymous: false,
    notes: "",
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "anonymous") {
      setFormData((prevData) => ({
        ...prevData,
        anonymous: checked,
        donorName: checked ? "" : prevData.donorName,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    navigate('/pages/Stripe',{state:{
      product:formData,
      amount:formData.amount,
      type:"donation"
     }} );
   
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission logic here
  //   console.log('Name:', name || 'Anonymous');
  //   console.log('Reason:', reason);
  //   console.log('Amount:', amount);
  // };

  return (
    <div className="max-w-screen-xl mt-10 mx-auto flex">
      <div
        className={`flex-1 ml-4 border border-gray-300 rounded-md p-4 ${
          window.innerWidth < 800 ? "w-4/5" : "w-full"
        }`}
      >
        <Interweave content={content} />
      </div>

      <div className="flex-1">
        <div className="max-w-md mx-auto mt-10">
          <form onSubmit={handleSubmit}>
            <label className="flex items-center">
              <span className="mr-2 p-1">לימוד התורה של היום</span>

              <div style={{ marginBottom: "10px" }} className="relative">
                <DatePicker
                  selected={formData.date}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  isClearable
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
                {/* Custom Calendar Icon */}
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM12 17v-6M12 12l-4-4m4 4l4-4"></path>
                  </svg>
                </span>
              </div>

              <span className="p-1">יהיה:</span>
            </label>
            {/* <label className="flex items-center">
      <span className="mr-4 p-1"> לימוד התורה של היום</span>
         
          <div style={{ marginBottom: "10px" }}>
            <DatePicker
              selected={formData.date}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              isClearable
              //placeholderText="בחר תאריך"
              style={{ width: "100%" }}
            />
          </div>
          <span className=" p-1">יהיה:</span>
        </label> */}

            <label className="flex items-center">
              <span className="mr-4 p-1">לעילוי נשמת:</span>

              <input
                type="text"
                name="forSoulElevation"
                value={formData.forSoulElevation}
                className="flex-1 border border-gray-300 rounded-md p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                onChange={handleChange}
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </label>

            <label className="flex items-center">
              <span className="mr-4 p-1">לרפואות:</span>

              <input
                type="text"
                name="forHealing"
                value={formData.forHealing}
                onChange={handleChange}
                className="flex-1 border border-gray-300 rounded-md p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </label>

            <label className="flex items-center">
              <span className="mr-4 p-1">לישועת:</span>

              <input
                type="text"
                name="forSalvation"
                value={formData.forSalvation}
                onChange={handleChange}
                className="flex-1 border border-gray-300 rounded-md p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </label>

            <label className="flex items-center">
              <span className="mr-4 p-1">להצלחת:</span>
              <input
                type="text"
                name="forSuccess"
                value={formData.forSuccess}
                className="flex-1 border border-gray-300 rounded-md p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                onChange={handleChange}
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </label>

            {!formData.anonymous && (
              <label className="flex items-center">
                <span className="mr-4 p-1">נתנדב על ידי:</span>

                <input
                  type="text"
                  name="donorName"
                  value={formData.donorName}
                  onChange={handleChange}
                  className="flex-1 border border-gray-300 rounded-md p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                />
              </label>
            )}

            <label className="flex items-center justify-between">
              <span className="mr-4 p-1">הרוצה בעילום שמו *</span>

              <input
                type="checkbox"
                name="anonymous"
                className="border border-gray-300 rounded-md"
                checked={formData.anonymous}
                onChange={handleChange}
                style={{
                  [savedLang === "en" ? "marginLeft" : "marginRight"]: "auto",
                }} // Pushes the checkbox to the right
              />
            </label>

            <br />

            <label className="flex items-center">
              <span className="mr-4 p-1">הערות:</span>

              <textarea
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                rows="4"
                className="flex-1 border border-gray-300 rounded-md p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                style={{ width: "100%", marginBottom: "10px" }}
              ></textarea>
            </label>

            <label className="flex items-center">
              <span className="mr-4 p-1">סכום:</span>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                className="flex-1 border border-gray-300 rounded-md p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                placeholder="0.00"
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </label>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Donate
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Donate;
