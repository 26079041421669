import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import BASE_URL from "./constant";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import htmlToPlainText from "./htmltotext";
import { AuthContext } from "./context/AuthContext";

const MyBooks = () => {
  const { getUserData } = useContext(AuthContext);
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [slideData, setSlideData] = useState([]);

  useEffect(() => {
    const userdata = getUserData();
    fetchBooks(userdata);
  }, []);

  const fetchBooks = async (userdata) => {
    try {
      const url = `${BASE_URL}/api_book.php/favoritebooks?user_id=${userdata.id}`;
      const response = await axios.get(url);
      const bookData = response.data;
      if (Array.isArray(bookData)) {
        sliderData1(bookData);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  const sliderData1 = (books) => {
    const slideData = books.map((book) => ({
      id: book.id,
      image: "../images/download.png",
      title: book.book_name,
      book_id: book.book_id,
    }));
    setSlideData(slideData);
  };

  const { t } = useTranslation();

  return (
    <div className="relative px-8 my-5">
      <div className="title-fil w-full bg-[#0000007a] p-4 mb-2 items-center">
        <h1 className="text-3xl text-center text-white my-2 mr-4 flex-shrink-0">
          {t("my_books")}
        </h1>
      </div>

      <div>
        <div className="mslid" style={{ margin: "0px 12px" }}>
          {slideData.length > 0 ? (
            <Swiper
              slidesPerView={5}
              modules={[Navigation]}
              className="myhomeSwiperslider"
              navigation={{
                nextEl: nextRef.current,
                prevEl: prevRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.update();
                swiperRef.current = swiper;
              }}
            >
              {slideData.map((slide, index) => (
                <SwiperSlide key={index} className="myhomeSwiperslider">
                  <Link to={`/pages/Product/${slide.book_id}`}>
                    <div className="relative overflow-hidden group w-full h-[300px]">
                      <img
                        src={slide.image}
                        alt="Book"
                        className="w-full h-full transition-transform duration-300 transform group-hover:scale-110"
                      />
                      <div className="absolute top-2 left-1.5 h-full flex items-center justify-center transition-transform duration-300 transform group-hover:scale-110">
                        <span className="inline-block vrttext text-[#daa520] slide-text-my tilt-neon">
                          {htmlToPlainText(slide.title)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p style={{ color: "white", fontWeight: "bold" }}>{t("no_f_b_s")}</p>
          )}
        </div>
        <img src="../images/mybook3.png" />
        <button
          ref={prevRef}
          className="swiper-button-prev video-bnt"
          style={{
            position: "absolute",
            left: "2px",
            color: "white",
            fontWeight: "800",
            top: "56%",
            width: "36px",
            height: "35.997px",
          }}
        ></button>
        <button
          ref={nextRef}
          className="swiper-button-next video-bnt"
          style={{
            position: "absolute",
            right: "-2px",
            color: "white",
            fontWeight: "800",
            top: "56%",
            width: "36px",
            height: "35.997px",
          }}
        ></button>
      </div>
    </div>
  );
};

export default MyBooks;
